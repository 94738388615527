import { InfoOutlinedIcon } from "@l2r-front/l2r-icons"
import { styled } from "@l2r-front/l2r-ui"

export const ScrollableWrapper = styled("div")(({ theme }) => ({
    cursor: "grab",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    overflowX: "auto",
    paddingRight: theme.spacing(2),
    width: "100%",
}))

export const ChartWrapper = styled("div")(() => ({
    minWidth: 500,
    display: "flex",
    flexDirection: "column",
    width: "100%",
}))

export const NoBudgetInfo = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "row",
}))

export const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    color: theme.palette["objects/object-grey"].main,
    height: 22,
    marginRight: theme.spacing(2),
}))