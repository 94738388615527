import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { DETAIL_TAB_PARAM_KEY } from "../../../../common/constants/tabKeys"
import { verticalSigningQueryKeys } from "../queries/verticalSigning/queryKeys"
import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignUpdate(
    signId,
    poleId,
    queryParameters,
    config = {},
) {
    const url = `vertical_signs/${signId}/`
    const queryClient = useQueryClient()
    const { getParam, setParam } = useUrlParams()

    return useVerticalSigningMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.verticalSign(signId) })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.pole(poleId) })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.project() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.layer() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.stats_categories() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.stats_conditions() })

                const tabParam = getParam(DETAIL_TAB_PARAM_KEY)
                if (tabParam) {
                    const parts = tabParam.split("-")
                    parts[1] = data.vertical_sign_type
                    setParam(DETAIL_TAB_PARAM_KEY, parts.join("-"))
                }
            },
        },
    )
}